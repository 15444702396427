import {Bars3Icon, ChevronDownIcon, XMarkIcon} from '@heroicons/react/24/outline';
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {ComponentPropsWithoutRef, useCallback, useEffect, useRef, useState} from 'react';

import URLS, {getResourcesURL} from '../../../urls';
import Col from '../../common/Col';
import Row from '../../common/Row';
import {useBreakpoint} from '../../common/util/useBreakpoint';
import useCurrentUser from '../../query/useCurrentUser';
import Button from './buttons/Button';
import Dropdown from './Dropdown';
import SideNavigation from './SideNavigation';

type Props = ComponentPropsWithoutRef<'div'> & {
  scenarioIndex: number;
  onHeightChange: (height: number) => void;
};
const Navbar = ({className, scenarioIndex, onHeightChange, ...props}: Props) => {
  const {isTablet} = useBreakpoint('tablet');
  const navBarRef = useRef<HTMLDivElement>(null);
  const [navBarHeight, setNavBarHeight] = useState<number>();

  const blurActiveElement = useCallback(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, []);

  useEffect(() => {
    if (navBarRef.current) {
      const height = navBarRef.current.getBoundingClientRect().height;
      setNavBarHeight(height);
      onHeightChange(height);
    }
  }, [onHeightChange]);

  useResizeObserver(navBarRef, entry => {
    setNavBarHeight(entry.borderBoxSize[0].blockSize);
    onHeightChange(entry.borderBoxSize[0].blockSize);
  });

  return (
    <Row
      ref={navBarRef}
      className={classNames(
        'sticky top-0 z-50 justify-between items-center p-sm bg-white border-b border-gray-100',
        'tablet:bg-opacity-95 focus-within:bg-opacity-100 tablet:flex-row-reverse',
        className
      )}
      {...props}
    >
      <Link href={URLS.home} legacyBehavior className={'block'}>
        <a
          className={'flex-none leading-[1px]' /* Avoids extra bottom space on wrapping span generated by next/image */}
        >
          <Image
            src="/img/logo_navbar.svg"
            height={35}
            width={160}
            alt="Envirocrops logo"
            className={'cursor-pointer'}
            onClick={blurActiveElement}
          />
        </a>
      </Link>
      {isTablet ? (
        <Dropdown
          floating
          hideChevron
          skipReposition
          lockPageScroll
          buttonContent={
            <>
              <XMarkIcon className="w-sm hidden group-focus-within:flex" />
              <Bars3Icon className="w-sm flex group-focus-within:hidden" />
            </>
          }
          menuClassName={'!fixed !inset-0 !rounded-none !border-none bg-transparent backdrop-blur-sm'}
          menuStyle={{height: `calc(100vh - ${navBarHeight}px)`, marginTop: navBarHeight}}
        >
          <NavBarMenu scenarioIndex={scenarioIndex} />
        </Dropdown>
      ) : (
        <NavBarMenu scenarioIndex={scenarioIndex} />
      )}
    </Row>
  );
};

const NavBarMenu = ({scenarioIndex}: Pick<Props, 'scenarioIndex'>) => {
  const {isMobile} = useBreakpoint('mobile');
  const {isTablet} = useBreakpoint('tablet');
  const router = useRouter();
  const {asPath} = router;
  const currentUser = useCurrentUser();

  const [isSubmenuExpanded, setIsSubmenuExpanded] = useState(false);

  const toggleSubmenuExpanded = useCallback(() => {
    setIsSubmenuExpanded(value => !value);
  }, []);

  const getActiveClassname = useCallback(
    (...paths: string[]) => {
      return paths.some(path => asPath.startsWith(path)) ? 'font-semibold' : undefined;
    },
    [asPath]
  );

  return (
    <div
      className={classNames(
        'ml-xs flex flex-row flex-wrap items-center justify-end gap-x-md  bg-white',
        'tablet:ml-0 tablet:flex-col tablet:flex-nowrap tablet:items-stretch tablet:justify-stretch',
        'tablet:divide-gray-100 tablet:divide-y',
        '[&>:last-child]:tablet:!border-b-thin [&>:last-child]:tablet:border-b-gray-100',
        '[&>a]:tablet:fv-outline-internal [&_button]:tablet:fv-outline-internal'
      )}
    >
      {isMobile && currentUser && (
        <Col className="w-full bg-white">
          <Button
            className={classNames(isSubmenuExpanded ? '!bg-white' : '!bg-primary-700')}
            variant="navigation"
            data-no-autoclose
            onClick={toggleSubmenuExpanded}
          >
            <Row className={classNames('px-md justify-between', isSubmenuExpanded ? 'text-current' : 'text-white')}>
              <div className="w-10" />
              Dashboard
              <ChevronDownIcon
                className={classNames(
                  'w-sm h-sm transition-transform duration-300',
                  isSubmenuExpanded && '-scale-y-100'
                )}
              />
            </Row>
          </Button>
          {isSubmenuExpanded && (
            <div className="px-md">
              <SideNavigation scenarioIndex={scenarioIndex} />
            </div>
          )}
        </Col>
      )}
      {!isMobile && isTablet && currentUser && (
        <div className="bg-white tablet:py-xs">
          <Button
            className="!block mx-sm"
            href={scenarioIndex === undefined ? URLS.dashboard : URLS.app(scenarioIndex).dashboard}
            variant="primary"
            // onClick={handleCloseMenu}
          >
            Dashboard
          </Button>
        </div>
      )}
      <Button
        href={URLS.crops.index}
        variant="navigation"
        data-text={'Crops'}
        className={getActiveClassname(URLS.crops.index)}
      >
        Crops
      </Button>
      <Button
        href={URLS.about.index}
        variant="navigation"
        data-text={'About'}
        className={getActiveClassname(URLS.about.index)}
      >
        About
      </Button>
      <Button
        href={URLS.game}
        variant="navigation"
        data-text={'Cropper Game'}
        className={getActiveClassname(URLS.game)}
      >
        Cropper Game
      </Button>
      <Button
        href={getResourcesURL()}
        variant="navigation"
        data-text={'Resources'}
        className={getActiveClassname(getResourcesURL(), '/resource')}
      >
        Resources
      </Button>
      <Button
        href={URLS.marketPlace.products}
        variant="navigation"
        data-text={'Marketplace'}
        className={getActiveClassname(URLS.marketPlace.products)}
      >
        Marketplace
      </Button>
      <Button
        href={URLS.marketPlace.suppliers}
        variant="navigation"
        data-text={'Find suppliers'}
        className={getActiveClassname(URLS.marketPlace.suppliers)}
      >
        Find suppliers
      </Button>
      {!isTablet && <div className="border-r border-gray-150 h-[3rem]" />}
      {!currentUser && (
        <Button href={URLS.signin} variant="navigation" data-text={'Login'} className={getActiveClassname(URLS.signin)}>
          Login
        </Button>
      )}
      {currentUser && !isTablet && (
        <Button
          href={scenarioIndex === undefined ? URLS.dashboard : URLS.app(scenarioIndex).dashboard}
          variant="navigation"
          className={getActiveClassname(URLS.app(scenarioIndex).dashboard)}
        >
          Dashboard
        </Button>
      )}
      {!currentUser && (
        <div className="bg-white tablet:py-xs">
          <Button className="!block mx-sm" href={URLS.start} variant="primary">
            Get Started
          </Button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
