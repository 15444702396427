import classNames from 'classnames';
import React, {
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {ChevronDownIcon} from '../../../resources/Icons';
import Col from '../../common/Col';
import Scrollable from './Scrollable';

type PropsType = PropsWithChildren<{
  id?: string;
  buttonContent: ReactNode;
  variant?: 'primary' | 'secondary' | 'dark';
  floating?: boolean;
  className?: string; // Applies to whole component
  menuClassName?: string; // Applies to menu
  menuStyle?: React.CSSProperties;
  hideChevron?: boolean;
  disabled?: boolean;
  // Defaults to 'down'
  position?: 'up' | 'down';
  skipReposition?: boolean;
  lockPageScroll?: boolean;
}>;

/**
 * The dropdown will close when clicked inside. If you want any specific focusable (i.e. link, button, etc.) to not
 * close the dropdown, add the `data-no-autoclose` attribute to it.
 */
const Dropdown = (props: PropsType) => {
  const {
    id,
    buttonContent,
    variant = 'primary',
    children,
    floating,
    className,
    menuClassName,
    menuStyle,
    hideChevron,
    disabled,
    position = 'down',
    skipReposition,
    lockPageScroll,
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const getFocusable = useCallback(
    () =>
      menuRef.current?.querySelectorAll(
        'a[href], button:not(:disabled), input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      ),
    []
  );

  /**
   * Close the dropdown if the focus is outside the button and the menu
   */
  const closeIfNotFocusedWithin = useCallback(() => {
    const shouldClose =
      !buttonRef.current?.contains(document.activeElement) && !menuRef.current?.contains(document.activeElement);
    if (shouldClose) setIsOpen(false);
  }, []);

  const handleButtonMouseDown = useCallback((e: MouseEvent) => {
    const currentTarget = e.currentTarget as HTMLElement | null;
    setIsOpen(prevState => !prevState);

    // The following is needed for Safari, as this browser by default doesn't focus the button when it's clicked
    // By focusing the button we ensure that the menu is closed when the button is blurred
    e.preventDefault();
    currentTarget?.focus();
  }, []);

  const handleButtonBlur = useCallback(() => {
    // Use a timeout to let the focus move to clicked element in Safari
    setTimeout(closeIfNotFocusedWithin, 100);
  }, [closeIfNotFocusedWithin]);

  const handleButtonKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        setIsOpen(prevState => !prevState);
      }
      if (e.key === 'ArrowDown') {
        const focusable = getFocusable();
        if (focusable?.length) {
          e.preventDefault();
          (focusable[0] as HTMLElement)?.focus();
        }
      }
    },
    [getFocusable]
  );

  const handleMenuClick = useCallback(
    (e: MouseEvent) => {
      const target = e.target as Element | null;
      const focusable = getFocusable();
      if (focusable) {
        const clickedFocusable = Array.from(focusable).find(f => f === target || f.contains(target));
        (clickedFocusable as HTMLElement)?.focus();
        if (clickedFocusable && (clickedFocusable as HTMLElement).dataset['noAutoclose']) {
          // Avoid closing the menu if the clicked element has the `data-no-autoclose` attribute
          return;
        }
      }

      setIsOpen(false);
    },
    [getFocusable]
  );

  const handleMenuKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Tab') {
        setIsOpen(false);
        if (e.key === 'Escape') {
          buttonRef.current?.focus();
        }
        return;
      }
      const focusable = getFocusable();
      if (!focusable?.length) return;

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        const index = Array.from(focusable).findIndex(e => e === document.activeElement);
        if (index === -1 || index === focusable.length - 1) {
          (focusable[0] as HTMLElement).focus();
        } else {
          (focusable[index + 1] as HTMLElement).focus();
        }
        return;
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        const index = Array.from(focusable).findIndex(e => e === document.activeElement);
        if (index === -1 || index === 0) {
          (focusable[focusable.length - 1] as HTMLElement).focus();
        } else {
          (focusable[index - 1] as HTMLElement).focus();
        }
        return;
      }
    },
    [getFocusable]
  );

  const handleMenuBlur = useCallback(() => {
    // Use a timeout to let the focus move to clicked element in Safari
    setTimeout(closeIfNotFocusedWithin, 100);
  }, [closeIfNotFocusedWithin]);

  useEffect(() => {
    if (!lockPageScroll) return;

    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen, lockPageScroll]);

  // Whenever menu is open, adjust its position if it exceeds window boundaries
  useEffect(() => {
    if (skipReposition) return;

    const leftPadding = 36;
    const rightPadding = 22;

    if (isOpen && menuRef.current) {
      // Reset dropdown position
      menuRef.current.style.left = '';
      menuRef.current.style.right = '';
      menuRef.current.style.width = '';
      menuRef.current.style.transform = '';
      // Reduce dropdown width if it exceeds window width minus paddings
      let rect = menuRef.current.getBoundingClientRect();
      if (rect.width > window.innerWidth - leftPadding - rightPadding) {
        menuRef.current.style.width = window.innerWidth - leftPadding - rightPadding + 'px';
      }
      // Move dropdown to the right if it's outside window limit
      rect = menuRef.current.getBoundingClientRect();
      if (rect.left <= 0) {
        menuRef.current.style.right = rect.left - 28 + 'px';
      }
      // Move dropdown to the top if it's outside window limit
      if (rect.bottom > window.innerHeight) {
        console.log('bottom', rect.bottom, 'window.innerHeight', window.innerHeight);
        menuRef.current.style.transform = `translateY(${window.innerHeight - rect.bottom}px)`;
      }
    }
  }, [isOpen, skipReposition]);

  return (
    <div className={classNames('relative space-y-[.9rem] text-sm', className)}>
      <button
        id={id}
        ref={buttonRef}
        type="button"
        disabled={disabled}
        onMouseDown={disabled ? undefined : handleButtonMouseDown}
        onKeyDown={disabled ? undefined : handleButtonKeyDown}
        onBlur={disabled ? undefined : handleButtonBlur}
        className={classNames(
          'border-thin w-full inline-flex items-center justify-between gap-xs outline-none',
          variant === 'primary' && 'border-gray-100 enabled:hover:border-primary-300',
          variant === 'secondary' && 'border-transparent enabled:hover:border-gray-100',
          variant !== 'dark' && isOpen && 'border-primary-300',
          variant === 'dark' && 'text-white bg-primary-700 undisabled:hover:bg-primary-600 focus:bg-primary-600',
          'p-4 rounded-[10px]'
        )}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {buttonContent}
        {!hideChevron && (
          <ChevronDownIcon
            className={classNames(
              'w-[1.4rem] shrink-0 transition-transform duration-300',
              !disabled && isOpen && ['-rotate-180', variant !== 'dark' && 'text-primary-300']
            )}
          />
        )}
      </button>
      {
        <Col
          ref={menuRef}
          onClick={handleMenuClick}
          onKeyDown={handleMenuKeyDown}
          onBlur={handleMenuBlur}
          className={classNames(
            'border-thin border-gray-100 rounded-[10px] bg-white overflow-hidden',
            isOpen ? 'visible' : 'invisible',
            floating && 'absolute z-10',
            floating && position === 'down' && 'top-[4.6rem] right-0',
            floating && position === 'up' && 'bottom-[5.6rem] right-0',
            menuClassName
          )}
          style={menuStyle}
          role="menu"
        >
          <Scrollable>{children}</Scrollable>
        </Col>
      }
    </div>
  );
};

export default Dropdown;
